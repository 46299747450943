@import './styles/variables.scss';
@import './styles/base.scss';
@import './styles/typography.scss';

.app-wrap {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.temp-svg {
  margin-bottom: 8rem;
  width: 80vw;
  max-width: 600px;
  height: auto;
}

.temp-footer {
  text-align: center;
  
  @media (max-width: 599px) {
    margin-bottom: 8rem;
  }
  @media (min-width: 600px) {
    margin-bottom: 6rem;
  }
}

.temp-footer__copy {
  padding-bottom: .5rem;
  color: var(--color-gray-4);
}

.temp-footer__email {
  color: var(--color-gray-4);
  transition: color .3s ease;
  
  &:hover,
  &focus {
    color: var(--color-gray-5)
  }
}